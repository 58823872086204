var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "win-layer-pop",
        { staticClass: "common-bl-area" },
        [
          _vm.commonComponent
            ? _c(_vm.commonComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.commonPopParams },
                on: {
                  close: _vm.commonClosePopup,
                  selectFunc: _vm.commonSelectFunc,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }